import React from "react";
import {
  Grid,
  Container,
  styled,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";
import {
  StyledTypographyContent,
  StyledTypographyTitle,
} from "../components/StyledTypography";
import { downVariant, upVariant } from "../components/AnimationVariants";
import { StyledBoxContainer, StyledBoxItem } from "../components/StyledBox";
import { SEO } from "../components/seo";

const AboutUs: React.FC = () => {
  const wideScreen = useMediaQuery("(min-width:600px)");

  const StyledTypography = styled(StyledTypographyTitle)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  })) as typeof Typography;

  const StyledTypographyDesc = styled(StyledTypographyContent)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  }));

  const StyledContainer = styled(StyledBoxContainer)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      gap: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px",
    },
  }));

  const BoxItem = styled(StyledBoxItem)(({ theme }) => ({
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "8px",
    },
  }));

  const controls = useAnimation();

  return (
    <InView
      as="div"
      onChange={(inView) => {
        inView ? controls.start("visible") : "";
      }}
      triggerOnce={true}
      threshold={0.2}
      style={{
        background:
          "transparent linear-gradient(270deg, #FFFFFF 0%, #E8F8FF 100%) 0% 0% no-repeat padding-box",
        opacity: 1,
      }}
    >
      <Layout>
        <Container maxWidth={"lg"} disableGutters>
          <StyledContainer
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10vh",
              paddingBottom: "0",
              gap: 0,
            }}
          >
            <BoxItem>
              <motion.div
                animate={controls}
                initial="hidden"
                variants={downVariant}
              >
                <StyledTypography component={"h2"}>Who we are</StyledTypography>
              </motion.div>
            </BoxItem>
            <StyledBoxItem
              sx={{
                height: { laptop: "100%" },
                width: { laptop: "100%" },
                maxWidth: { laptop: "70%" },
              }}
            >
              <motion.div
                animate={controls}
                initial="hidden"
                variants={upVariant}
              >
                <StyledTypographyDesc gutterBottom>
                  We are a friendly team based in Surabaya, Indonesia. We have
                  delivered and been involved in various mobile and web
                  applications, backend development projects . Our pricing is
                  very competitive and, yes, <i>we do speak English fluently</i>
                  .
                </StyledTypographyDesc>
              </motion.div>
            </StyledBoxItem>
          </StyledContainer>
          {wideScreen ? (
            <StaticImage alt="bgabout" src="../images/about_web.png" />
          ) : (
            <StaticImage alt="bgabout" src="../images/about_mobile.png" />
          )}
        </Container>
        <ContactForm />
      </Layout>
    </InView>
  );
};

export default AboutUs;

export const Head = () => <SEO title="About Us | Garasilabs" />;
